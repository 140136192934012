<template>
  <header>
		<div class="container">
			<div class="top-line">
				<img class="logo" src="~@/assets/img/logo.svg" alt="">
				<p class="tagline">Безупречная работа с 1994 года!</p>
				<div class="user-info">
					<div class="user-photo"></div>
					<div class="user-text">
						<p class="name">{{ currentUser.full_name }}</p>
						<p class="logout" @click.prevent="logout()">
							<svg width="10" height="16" viewBox="0 0 10 16" fill="none">
								<path d="M2.99867 0V0.705624H0.239136V14.7843H1.25933V1.72582H2.99867V16L9.76089 14.7843V12.7949V1.21827L2.99867 0ZM4.24268 8.54835C4.01536 8.54835 3.83106 8.30857 3.83106 8.01277C3.83106 7.717 4.01536 7.47721 4.24268 7.47721C4.46999 7.47721 4.65429 7.717 4.65429 8.01277C4.65429 8.30857 4.46999 8.54835 4.24268 8.54835Z" fill="#04B67D"/>
							</svg>
							Выйти из ЛК
						</p>
					</div>
				</div>
			</div>
		</div>
    <div class="menu-wrap">
			<div class="container">
				<ul class="menu">
					<li><router-link :to="{ name: 'Home' }">Главная</router-link></li>
					<li><router-link to="">База</router-link></li>
					<li><router-link to="">Мои клиенты</router-link></li>
					<li><router-link to="">Избранные</router-link></li>
					<li><router-link to="">О нас</router-link></li>
					<li><router-link to="">Статистика</router-link></li>
					<li><router-link to="">Реклама</router-link></li>
					<li><router-link to="">ЖК</router-link></li>
					<li><router-link to="">Знания</router-link></li>
					<li><router-link :to="{ name: 'AdminUsers' }"><svg width="16" height="20" viewBox="0 0 16 20" fill="none"><path d="M8.00007 9.78131C10.2237 9.78131 12.0265 7.59171 12.0265 4.89069C12.0265 2.1896 11.4346 0 8.00007 0C4.56551 0 3.97351 2.1896 3.97351 4.89069C3.97351 7.59171 5.77631 9.78131 8.00007 9.78131Z" fill="white"/><path d="M0.394833 17.2487C0.394149 17.0841 0.393464 17.2023 0.394833 17.2487V17.2487Z" fill="white"/><path d="M15.6049 17.3774C15.607 17.3323 15.6056 17.0646 15.6049 17.3774V17.3774Z" fill="white"/><path d="M15.5963 17.0513C15.5217 12.346 14.9072 11.0053 10.2048 10.1566C10.2048 10.1566 9.54283 11.0001 7.99997 11.0001C6.45712 11.0001 5.79506 10.1566 5.79506 10.1566C1.14392 10.996 0.492033 12.3169 0.406318 16.8984C0.39929 17.2725 0.396033 17.2922 0.394775 17.2487C0.395061 17.3301 0.395404 17.4806 0.395404 17.7431C0.395404 17.7431 1.51495 20 7.99997 20C14.4849 20 15.6045 17.7431 15.6045 17.7431C15.6045 17.5745 15.6047 17.4572 15.6048 17.3774C15.6036 17.4043 15.6011 17.3522 15.5963 17.0513Z" fill="white"/></svg></router-link></li>
				</ul>
			</div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters([
			'isLogged',
			'currentUser'
		])
	},
	methods: {
		logout() {
			this.$store.dispatch('logout')
				.then(() => {
					this.$router.push({ name: 'Login' })
				})
				.catch(err => {
					console.log(err)
				})
		}
	}
}
</script>

<style lang="sass">
header
	.top-line
		display: flex
		justify-content: space-between
		align-items: center
		padding: 20px 0
		.tagline
			font-size: 24px
			margin: 0
		.user-info
			display: flex
			align-items: center
			.user-photo
				width: 60px
				height: 60px
				border-radius: 50%
				overflow: hidden
				margin-right: 12px
				background: #DDDDDE
			.name
				margin-bottom: 8px
				font-size: 16px
				font-weight: 700
			.logout
				display: inline-flex
				align-items: center
				margin: 0
				color: #04B67D
				cursor: pointer
				svg
					margin-right: 6px
					margin-top: -1px
	.menu-wrap
		background: #04B67D
	.menu
		height: 50px
		display: flex
		align-items: center
		color: #fff
		font-size: 22px
		font-weight: 600
		li
			height: 100%
			a
				display: flex
				align-items: center
				height: 100%
				padding: 0 30px
			&:first-child
				a
					padding-left: 0
			&:last-child
				a
					padding-right: 0
@media only screen and (max-width : 1415px)
	header
		.menu
			font-size: 20px
			justify-content: space-between
			li
				a
					padding: 0 20px
@media only screen and (max-width : 1200px)
	header
		.menu
			font-size: 17px
@media only screen and (max-width : 1000px)
	header
		.top-line
			padding: 10px 0
			.logo
				max-width: 160px
			.tagline
				font-size: 18px
			.user-info
				.name
					font-size: 14px
				.logout
					font-size: 13px
		.menu
			font-size: 16px
			li
				a
					padding: 0 10px
</style>